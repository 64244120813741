import { Component, Input, OnInit } from '@angular/core';

import { CommonService } from '../services/common.service';
import { ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../services/loader.service';
import { AddressBookComponent } from '../address-book/address-book.component';

@Component({
  selector: 'app-bulk-address-upload',
  templateUrl: './bulk-address-upload.component.html',
  styleUrls: ['./bulk-address-upload.component.css']
})
export class BulkAddressUploadComponent implements OnInit {
  isValidFile: boolean = false;
  @Input() parentComponent: AddressBookComponent;

  @Input() uploadOrderId;
  uploadStatus:boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor( private httpService: CommonService,    private  _loaderService: LoadingService
,    private http: HttpClient,public activeModal: NgbActiveModal,private toastService: ToastrService) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }


  validateFile(event: any) {
    const file = event.target.files[0];
    if (file) {
        const allowedExtensions = ['xls', 'xlsx'];
        const fileType = file.name.split('.').pop()?.toLowerCase();

        if (allowedExtensions.includes(fileType)) {
            this.isValidFile = true;
        } else {
            this.toastService.error('Only Excel files (.xls, .xlsx) are allowed!');
            event.target.value = ''; // Reset file input
            this.isValidFile = false;
        }
    } else {
        this.isValidFile = false;
    }
}


  clearFile(){
    this.activeModal.close();
  }
  upload() {
    this.uploadStatus = true;
    const fileInput: HTMLInputElement = this.fileInput.nativeElement;
  
    if (fileInput.files && fileInput.files.length > 0) {
      const file: File = fileInput.files[0];
      const formData: FormData = new FormData();
      formData.append("file", file);
  
      let customerId = JSON.parse(localStorage.getItem("loggedInUserDetails")).uuid;
      const url = `${environment.apiPath}/api/addressBook/addAddress/readXls?customerId=${customerId}`;
  
      this.http.post(url, formData, { withCredentials: true }).subscribe(
        (response: any) => {
          this._loaderService.stringSubject.next(false);
          this.toastService.success("File uploaded successfully");
  
          // ✅ Call fetchListOfDataToAppend() after successful upload
          if (this.parentComponent) {
            this.parentComponent.fetchListOfDataToAppend();
          }
  
          this.activeModal.close();
          this.uploadStatus = false;
        },
        (error: any) => {
          this.uploadStatus = false;
          this._loaderService.stringSubject.next(false);
  
          if (error.status === 409) {
            this.toastService.error("Document is already present");
          } else {
            this.toastService.error(error.error.message);
          }
  
          console.error("Error uploading file:", error);
          this.activeModal.close();
        }
      );
    } else {
      this.uploadStatus = false;
      this._loaderService.stringSubject.next(false);
      this.clearFile();
      this.toastService.error("No file selected. Please choose a file to upload.");
    }
  }
  


}
