<div style="position: relative; top: 40px; padding: 30px; border: 1px solid #ddd;">
    <input type="file" #fileInput accept=".xls,.xlsx" (change)="validateFile($event)" style="padding-right: 30px;" />
</div>

<span 
(click)="clearFile()" 
style="
    position: absolute;
    top: -10px;
    right: 20px;
    font-size: 20px;
    color: #FF0000;
    cursor: pointer;
">
&times;
</span>
<h3 style="
position: absolute;
    top: -13px;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;

">Upload Bulk Address</h3>


<div class="row" style="width: 98%; margin-left: 8px;">
    <div class="col-12" style="margin-top: 20px;">                             
        <div class="row">
            <div class="col-12" style="margin-top: 30px; margin-bottom: 20px; text-align: center;">
                <button (click)="upload()" [disabled]="uploadStatus || !isValidFile" style="
                    box-shadow: none;
                    text-transform: capitalize;
                    background-color: #FF9A00;
                    border: 1px solid #0000001A;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 8px 19px;
                    cursor: pointer;
                    border-radius: 0 !important;
                    margin-top: 15px;
                ">Upload</button>
            </div>
        </div>
    </div>
</div>
